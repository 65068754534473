<template>
  <div ref="_carousel" class="carousel">
    <div class="carousel-wrapper">
      <div v-for="(item, i) in items" :key="i" class="carousel-slide">
        <img :src="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    // 图片URLS地址
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      containerWidth: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.containerWidth = this.$refs._carousel.clientWidth
      this.move()
    })
  },
  methods: {
    resetContainerWidth(width) {
      this.containerWidth = width
      const container = this.$refs._carousel
      const wrapper = container.querySelector('.carousel-wrapper')
      wrapper.style.width = (wrapper.children.length * this.containerWidth) + 'px';
      const slides = container.querySelectorAll('.carousel-slide')
      slides.forEach(slide => {
        slide.style.width = this.containerWidth + 'px';
      });
    },
    move() {
      const container = this.$refs._carousel
      const wrapper = container.querySelector('.carousel-wrapper')

      let x = 0;
      // 保存轮播图的起始位置
      let startX = 0;
      let startPoint = {};
      // 当前图片的索引
      let now = 0;

      let isMove = false;
      let isFirst = false;

      const length = wrapper.children.length;
      wrapper.innerHTML += wrapper.innerHTML;

      // 设置整个容器的宽度
      wrapper.style.width = (wrapper.children.length * this.containerWidth) + 'px';
      const slides = container.querySelectorAll('.carousel-slide')
      slides.forEach(slide => {
        slide.style.width = this.containerWidth + 'px';
      });

      console.log(wrapper.children.length, this.containerWidth, wrapper.style.width)

      container.addEventListener('touchstart', function(event) {
        isMove = false;
        isFirst = true;

        wrapper.style.transition = ''

        startPoint = {
          x: event.changedTouches[0].pageX,
          y: event.changedTouches[0].pageY
        }

        if (now === 0) {
          now = length
        } else if (now === wrapper.children.length - 1) {
          now = length - 1
        }

        // 计算出当前图片的偏移量
        x = -now * container.clientWidth;
        startX = x;

        // 重置容器的偏移量
        wrapper.style.transform = wrapper.style.WebkitTransform = `translate3d(${x}px,0,0)`
      });

      container.addEventListener('touchmove', function(event) {
        let newPoint = {
          x: event.changedTouches[0].pageX,
          y: event.changedTouches[0].pageY
        };
        let dis = {
          x: newPoint.x - startPoint.x,
          y: newPoint.y - startPoint.y
        }
        // console.log('x: %o, y: %o, r: %o, isFirst: %o', dis.x, dis.y, Math.abs(dis.x - dis.y), isFirst)

        // 判断是否横向移动
        if (isFirst && Math.abs(dis.x - dis.y) > 2) {
          if (Math.abs(dis.x) > Math.abs(dis.y)) {
            isMove = true;
          }
          isFirst = false;
        }

        if (isMove) {
          x = startX + dis.x;
          wrapper.style.transform = wrapper.style.WebkitTransform = `translate3d(${x}px,0,0)`
          event.preventDefault();
        }
      });
      container.addEventListener('touchend', function(event) {
        if (isMove) {
          let newPoint = event.changedTouches[0].pageX;

          // 右向左滑动，dis是负数，now+1；左向右滑动，dis是整数，now-1
          let dis = newPoint - startPoint.x;
          if (Math.abs(dis) > 100) {
            now -= dis / Math.abs(dis)
          }
          x = -now * container.clientWidth;

          wrapper.style.transition = '.3s'
          wrapper.style.transform = wrapper.style.WebkitTransform = `translate3d(${x}px,0,0)`
        }
      });
    }
  }
}
</script>

<style lang="less">
// 容器的宽度是单个item的宽度
.carousel {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;

  z-index: 20;

  // wrapper的宽度等于所有item宽度的总和
  .carousel-wrapper {
    display: flex;
  }

  .carousel-slide {}

  img {
    display: block;
    width: 100%;
  }
}
</style>